import { createSelector } from "@reduxjs/toolkit";
import Campaign, { CampaignPricingRule } from "../../models/campaigns/Campaign";
import { RootState } from "../store";
import ImageSrc, { parseImgUuid } from "../../models/ImageSrc";

export const selectCampaignsMap = createSelector([(state: RootState) => state.menuData.campaigns], (campaigns) => {
  const map: Record<string, Campaign> = {};

  campaigns.forEach((apiCampaign) => {
    let type = null;
    switch (apiCampaign.type) {
      case CampaignPricingRule.NONE:
        type = CampaignPricingRule.NONE;
        break;
      case CampaignPricingRule.WAS_NOW:
        // TODO
        break;
      case CampaignPricingRule.BUY_X_PAY_Y:
        type = CampaignPricingRule.BUY_X_PAY_Y;
        break;
      case CampaignPricingRule.X_FOR_Y:
        type = CampaignPricingRule.X_FOR_Y;
        break;
      case CampaignPricingRule.X_PERCENTAGE_OFF:
        type = CampaignPricingRule.X_PERCENTAGE_OFF;
        break;
      case CampaignPricingRule.XTH_ITEM_Y_PERCENTAGE_OFF:
        type = CampaignPricingRule.XTH_ITEM_Y_PERCENTAGE_OFF;
        break;
    }

    if (type) {
      let imageSrcs: ImageSrc[] = [];
      if (apiCampaign.img_uuids) {
        imageSrcs = JSON.parse(apiCampaign.img_uuids).map((imgUuid: string) => parseImgUuid(imgUuid));
      }

      map[apiCampaign.id] = {
        id: apiCampaign.id,
        apiId: String(apiCampaign.apiId1),
        type: type,
        name: apiCampaign.name,
        startDateTime: new Date(apiCampaign.start_date_time),
        endDateTime: new Date(apiCampaign.end_date_time),
        imageSrcs: imageSrcs,
        Xs: apiCampaign.custom_data ? JSON.parse(apiCampaign.custom_data) : undefined,
        updatedAt: "",
      };
    }
  });
  return map;
});

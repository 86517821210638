import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useCallback, useEffect, useRef } from "react";

import { v4 as uuidv4 } from "uuid";
import store, { RootState, useAppSelector } from "../../utils/redux/store.tsx";

type LoadingStore = {
  uuids: {
    [uuid: string]: true;
  };
};

const initialState: LoadingStore = { uuids: {} };

const slice = createSlice({
  name: "loading",
  initialState: initialState,
  reducers: {
    loadingStarted: (state, action: PayloadAction<string>) => {
      state.uuids[action.payload] = true;
    },
    loadingStopped: (state, action: PayloadAction<string>) => {
      delete state.uuids[action.payload];
    },
  },
});

// Action creators are generated for each case reducer function
export const { loadingStarted, loadingStopped } = slice.actions;

export const loadingScreenReducer = slice.reducer;

export function useLoadingScreen(isLoading?: boolean) {
  const uuid = useRef<string>("");
  const isLoadingInStore = useAppSelector((state) => state.loadingScreen.uuids[uuid.current]);
  useEffect(() => {
    uuid.current = uuidv4();

    return () => {
      store.dispatch(loadingStopped(uuid.current));
    };
  }, []);

  useEffect(() => {
    if (!isLoadingInStore && isLoading) {
      store.dispatch(loadingStarted(uuid.current));
    } else if (isLoadingInStore && isLoading === false) {
      store.dispatch(loadingStopped(uuid.current));
    }
  }, [isLoading, isLoadingInStore]);

  return {
    startLoading: useCallback(() => {
      store.dispatch(loadingStarted(uuid.current));
    }, []),
    stopLoading: useCallback(() => {
      store.dispatch(loadingStopped(uuid.current));
    }, []),
  };
}

export function isLoading() {
  const uuids = store.getState().loadingScreen.uuids;

  return Object.keys(uuids).length > 0;
}

export function useIsLoading() {
  const uuids = useAppSelector((state: RootState) => state.loadingScreen.uuids);

  return Object.keys(uuids).length > 0;
}

import { createSelector } from "@reduxjs/toolkit";
import { arrangementsApi } from "./arrangementsApi.ts";
import _ from "lodash";

export const selectTableState = createSelector(
  [arrangementsApi.endpoints.fetchTableState.select(), (state) => state.dev.arrangementTableItems],
  (tableState, arrangementTableItems) => {
    if (arrangementTableItems) {
      tableState = _.cloneDeep(tableState);
      if ("data" in tableState && tableState.data && "data" in tableState.data) {
        tableState.data.data.table_items = arrangementTableItems;
      }
    }

    return tableState;
  }
);

import { rtkQueryApi } from "./rtkQueryApi";
import _ from "lodash";
import { setServerTimestamp } from "../globalSlice";
import {
  Arrangement,
  ArrangementGroup,
  FetchArrangementsSettingsResponse,
  FetchTableStateResponse,
  PreDiningArrangement,
  SupplementaryArrangement,
} from "../../../../types/qr/arrangements.ts";

const arrangementsEndpoints = rtkQueryApi.enhanceEndpoints({
  addTagTypes: ["get-table-state"],
});

export const arrangementsApi = arrangementsEndpoints.injectEndpoints({
  endpoints: (builder) => ({
    refreshTableState: builder.mutation<void, void>({
      query: () => {
        return {
          url: `/v5_2/qr/arrangements/refresh-table-state`,
          method: "POST",
        };
      },
      invalidatesTags: ["get-table-state"],
    }),
    fetchTableState: builder.query<FetchTableStateResponse, void>({
      query: () => {
        return {
          url: `/v5_2/qr/arrangements/table-state`,
          method: "GET",
        };
      },
      providesTags: ["get-table-state"],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: response } = await queryFulfilled;
          dispatch(setServerTimestamp(response.timestamp));
        } catch (e) {
          console.log(e);
        }
      },
    }),
    fetchArrangementsSettings: builder.query<
      {
        arrangements: Arrangement[];
        arrangement_groups: ArrangementGroup[];
        supplementary_arrangements: SupplementaryArrangement[];
        pre_dining_arrangement?: PreDiningArrangement;
      },
      void
    >({
      query: () => {
        return {
          url: `/v5_2/qr/arrangements`,
          method: "GET",
        };
      },
      transformResponse: (baseQueryReturnValue: FetchArrangementsSettingsResponse) => {
        const {
          arrangements,
          pre_dining_arrangement,
          supplementary_arrangements,
          arrangement_groups,
        } = baseQueryReturnValue.data;

        return {
          arrangements: arrangements.map(
            ({
              arrangement_arrangement_groups,
              arrangement_products,
              id,
              max_number_of_items_per_round_per_cover,
              max_rounds,
              max_time_in_minutes,
              minutes_between_every_round,
              visit_length,
              allow_extending_visits,
              minutes_added_per_time_extension,
              time_extension_product_id,
              visit_extension_calculation_method,
              round_extension_product_id,
              name,
              almost_done_reminder_enabled,
            }) => ({
              id,
              name,
              max_number_of_items_per_round_per_cover,
              max_time_in_minutes,
              max_rounds,
              minutes_between_every_round,
              visit_length,
              allow_extending_visits,
              time_extension_product_id,
              round_extension_product_id,
              minutes_added_per_time_extension,
              visit_extension_calculation_method,
              arrangementArticleIds: _.chain(arrangement_products)
                .keyBy("product_id")
                .mapValues(() => true)
                .value(),
              arrangementGroupIds: _.chain(arrangement_arrangement_groups)
                .keyBy("arrangement_group_id")
                .mapValues(() => true)
                .value(),
              almost_done_reminder_enabled,
            })
          ),
          supplementary_arrangements: supplementary_arrangements.map(
            ({ arrangement_arrangement_groups, arrangement_products, id, name }) => ({
              arrangementArticleIds: _.chain(arrangement_products)
                .keyBy("product_id")
                .mapValues(() => true)
                .value(),
              arrangementGroupIds: _.chain(arrangement_arrangement_groups)
                .keyBy("arrangement_group_id")
                .mapValues(() => true)
                .value(),
              id,
              name,
            })
          ),
          pre_dining_arrangement: pre_dining_arrangement
            ? {
                id: pre_dining_arrangement.id,
                name: pre_dining_arrangement.name,
                origin: pre_dining_arrangement.origin,
                arrangementGroupIds: _.chain(pre_dining_arrangement.arrangement_arrangement_groups)
                  .keyBy("arrangement_group_id")
                  .mapValues(() => true)
                  .value(),
              }
            : undefined,
          arrangement_groups: arrangement_groups.map(
            ({
              arrangement_group_products,
              arrangement_group_menus,
              id,
              maximum_order_rounds,
              maximum_per_ticket,
              max_per_round_per_cover,
              name,
              v5_credit_cost,
            }) => {
              return {
                id,
                name,
                maximum_per_ticket,
                maximum_order_rounds,
                max_per_round_per_cover,

                articleIdsInGroup: _.chain(arrangement_group_products)
                  .keyBy("product_id")
                  .mapValues(() => true)
                  .value(),
                articlegroupIdsInGroup: _.chain(arrangement_group_menus)
                  .keyBy("menukaart_id")
                  .mapValues(() => true)
                  .value(),
                view_options: { hidden: false, sortKey: 0 },
                credit_cost: v5_credit_cost,
              };
            }
          ),
        };
      },
    }),
  }),
});

export const {
  useLazyFetchTableStateQuery,
  useLazyFetchArrangementsSettingsQuery,
  useFetchTableStateQuery,
  useFetchArrangementsSettingsQuery,
  useRefreshTableStateMutation,
} = arrangementsApi;

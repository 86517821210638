import OrderArticle, { calculateOrderarticlePriceSum } from "../../models/order/OrderArticle";

import { TransactionCostsConfig } from "../globalSlice";

export function calculateNewTransactionCosts(
  orderItems: OrderArticle[],
  transactionCostsConfig: TransactionCostsConfig
) {
  const transaction_cost_product = orderItems.find(
    (item) => String(item.article.id) === String(transactionCostsConfig.product_id)
  );

  const orderPrice = calculateOrderarticlePriceSum(orderItems) - (transaction_cost_product?.article.price ?? 0);

  if (orderPrice < transactionCostsConfig.max_value) {
    if (transactionCostsConfig.calculation_method === "fixed") {
      return transactionCostsConfig.calculation_value;
    } else if (transactionCostsConfig.calculation_method === "percentage") {
      return orderPrice * (transactionCostsConfig.calculation_value / 100);
    }
  }

  return 0;
}

import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, useAppSelector } from "./store";
import { selectSumOfDiscountsPerCampaign } from "./selectors/discountsPerCampaign";
import { calculateNewTransactionCosts } from "./listeners/transactionCosts";
import { calculateOrderarticlePriceSum } from "../models/order/OrderArticle";
import { selectAutoAddedItems } from "./shoppingCartSlice";
import { selectAllPackagingsByUserInput } from "./packagingsSlice";
import { selectCheckCalculatorDiscounts } from "./selectors/selectCheckCalculatorDiscounts.ts";
import { selectItemsOrderValue } from "./selectors/selectItemsOrderValue.tsx";
import { selectVoucherOrderItems } from "./selectors/selectVoucherOrderItems.tsx";

/**
 * Sub total amount = itemsOrderValue + vouchersAmount - discountsCampaignAmount
 * Total amount = itemsOrderValue + vouchersAmount - discountsCampaignAmount + deliveryFeeAmount + transactionFeeAmount + tipAmount
 */

interface AccountState {
  credits: {
    tipAmount: number;
  };
  debits: {
    piggyGiftcardsAmount: number;
    paymentMethodAmount: number;
  };
  userIsDoingPayAfterOrder: boolean;
}

const initState: AccountState = {
  credits: {
    tipAmount: 0,
  },
  debits: {
    piggyGiftcardsAmount: 0,
    paymentMethodAmount: 0,
  },
  userIsDoingPayAfterOrder: false,
};

export const slice = createSlice({
  name: "account",
  initialState: initState,
  reducers: {
    userChangedTipAmount: (state, action: PayloadAction<number>) => {
      state.credits.tipAmount = action.payload;
    },
    userStartedPayAfterOrder: (state) => {
      state.userIsDoingPayAfterOrder = true;
    },
    userCanceledPayAfterOrder: (state) => {
      state.userIsDoingPayAfterOrder = false;
    },
    userFinishedPayAfterOrder: (state) => {
      state.userIsDoingPayAfterOrder = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  userChangedTipAmount,
  userFinishedPayAfterOrder,
  userStartedPayAfterOrder,
  userCanceledPayAfterOrder,
} = slice.actions;

export const selectAutoAddedItemsOrderValue = createSelector([selectAutoAddedItems], (items) => {
  return calculateOrderarticlePriceSum(items);
});

export const selectPackagingItemsOrderValue = createSelector([selectAllPackagingsByUserInput], (items) => {
  return calculateOrderarticlePriceSum(items);
});

export const selectTransactionFeeAmount = createSelector(
  [
    (state: RootState) => state.shoppingCart.items,
    (state: RootState) => state.shoppingCart.jamezzPaymentMethod,
    (state: RootState) => state.global.salesarea.transaction_costs,
    (state: RootState) => state.global.salesarea.payDirect,
    (state: RootState) => state.account.userIsDoingPayAfterOrder,
  ],
  (items, jamezzPaymentMethod, transaction_costs_config, payDirect, userIsDoingPayAfterOrder) => {
    if (transaction_costs_config.enabled) {
      const cost = calculateNewTransactionCosts(items, transaction_costs_config);
      const paymethod = jamezzPaymentMethod.payMethod;
      const payprovider = jamezzPaymentMethod.payProvider;

      if (
        paymethod === "CONTANT" ||
        (!payDirect && !userIsDoingPayAfterOrder) ||
        (paymethod === "" && payprovider === "") ||
        cost <= 0
      ) {
        return 0;
      } else {
        return cost;
      }
    }
    return 0;
  }
);

export const selectDeliveryFeeAmount = createSelector(
  [
    (state: RootState) => state.global.salesarea.delivery_settings,
    (state: RootState) => state.shoppingCart.items,
    (state: RootState) => state.global.sessionState?.distance,
    selectItemsOrderValue,
  ],
  (delivery_config, items, distance_in_km, itemsOrderValue) => {
    if (delivery_config.enabled) {
      const orderPrice = itemsOrderValue;
      let last_scheme: { startDistance: number; stopDistance: number; price: number; id: string } | null = null;
      if (delivery_config.delivery_cost_scheme.length > 0) {
        last_scheme = delivery_config.delivery_cost_scheme[delivery_config.delivery_cost_scheme.length - 1];
      }

      if (orderPrice >= delivery_config.free_delivery_costs_above) {
        return 0;
      } else if (distance_in_km === null) {
        return last_scheme?.price ?? 0;
      } else {
        const suitable_delivery_scheme = delivery_config.delivery_cost_scheme.find(
          ({ startDistance, stopDistance }: { startDistance: any; stopDistance: any }) =>
            distance_in_km >= startDistance && distance_in_km < stopDistance
        );
        if (!suitable_delivery_scheme && !last_scheme?.price) {
          console.warn("Cannot determine delivery costs; check delivery cost per distance configuration.");
        } else {
          return suitable_delivery_scheme?.price ?? last_scheme?.price ?? 0;
        }
      }
    }

    return 0;
  }
);

export const selectVoucherOrderItemsAmount = createSelector([selectVoucherOrderItems], (orderArticles) => {
  return calculateOrderarticlePriceSum(orderArticles);
});

export const selectCheckCalculatorDiscountsAmount = createSelector(
  [selectCheckCalculatorDiscounts],
  (discountItems) => {
    return calculateOrderarticlePriceSum(discountItems ?? []);
  }
);

export function useAccountTotalValue() {
  return useAppSelector(selectAccountTotalValue);
}

export const selectAccountSubTotalValue = createSelector(
  [
    selectSumOfDiscountsPerCampaign,
    selectAutoAddedItemsOrderValue,

    selectItemsOrderValue,
    selectVoucherOrderItemsAmount,
    selectCheckCalculatorDiscountsAmount,
  ],
  (
    discountsPerCampaignAmount,
    autoAddedItemsOrderValue,
    itemsOrderValue,
    vouchersAmount,
    checkCalculatorDiscountsAmount
  ) => {
    return (
      vouchersAmount +
      checkCalculatorDiscountsAmount +
      autoAddedItemsOrderValue +
      itemsOrderValue -
      discountsPerCampaignAmount
    );
  }
);

export const selectAccountTotalValue = createSelector(
  [
    selectAccountSubTotalValue,
    selectPackagingItemsOrderValue,

    selectTransactionFeeAmount,
    selectDeliveryFeeAmount,
    (state) => state.account.credits.tipAmount,
  ],
  (subTotal, transactionFeeAmount, deliveryFeeAmount, tipAmount) => {
    return subTotal + transactionFeeAmount + deliveryFeeAmount + tipAmount;
  }
);
export function useAccountSubTotalValue() {
  return useAppSelector(selectAccountSubTotalValue);
}

export default slice.reducer;

import { createSelector } from "@reduxjs/toolkit";
import { getCampaignsByArticleId } from "../../models/menu/Menu";
import OrderArticle from "../../models/order/OrderArticle";
import { CampaignPricingRule } from "../../models/campaigns/Campaign";
import calculateBuyXPayYCampaign from "../../order/campaigns/calculateBuyXPayYCampaign";
import calculateXForYCampaign from "../../order/campaigns/calculateXForYCampaign";
import calculateXPercentageOffCampaign from "../../order/campaigns/calculateXPercentageOffCampaign";
import calculateXthItemYPercentageOffCampaign from "../../order/campaigns/calculateXthItemYPercentageOffCampaign";
import { selectCampaignsMap } from "./selectCampaignsMap";
import { selectArticleCampaignsMap } from "./selectArticleCampaignsMap";
import { RootState } from "../store";

export const selectDiscountsPerCampaign = createSelector(
  [(state: RootState) => state.shoppingCart.items, selectCampaignsMap, selectArticleCampaignsMap],
  (items: OrderArticle[], campaignsMap, articleCampaignsMap) => {
    const discountsPerCampaign: Record<string, number> = {};

    const orderArticlesPerCampaign: Record<string, OrderArticle[]> = {};
    items.forEach((orderArticle) => {
      const campaigns = getCampaignsByArticleId(campaignsMap, articleCampaignsMap, orderArticle.article.id);

      campaigns.forEach((campaign) => {
        if (!orderArticlesPerCampaign[campaign.id]) {
          orderArticlesPerCampaign[campaign.id] = [];
        }
        orderArticlesPerCampaign[campaign.id].push(orderArticle);
      });
    });

    Object.keys(orderArticlesPerCampaign).forEach((campaignId) => {
      const campaign = campaignsMap[campaignId];
      if (campaign) {
        if (campaign.type === CampaignPricingRule.BUY_X_PAY_Y) {
          discountsPerCampaign[campaignId] = calculateBuyXPayYCampaign(campaign, orderArticlesPerCampaign[campaignId]);
        } else if (campaign.type === CampaignPricingRule.X_FOR_Y) {
          discountsPerCampaign[campaignId] = calculateXForYCampaign(campaign, orderArticlesPerCampaign[campaignId]);
        } else if (campaign.type === CampaignPricingRule.X_PERCENTAGE_OFF) {
          discountsPerCampaign[campaignId] = calculateXPercentageOffCampaign(
            campaign,
            orderArticlesPerCampaign[campaignId]
          );
        } else if (campaign.type === CampaignPricingRule.XTH_ITEM_Y_PERCENTAGE_OFF) {
          discountsPerCampaign[campaignId] = calculateXthItemYPercentageOffCampaign(
            campaign,
            orderArticlesPerCampaign[campaignId]
          );
        }
      }
    });

    return discountsPerCampaign;
  }
);

export const selectSumOfDiscountsPerCampaign = createSelector(selectDiscountsPerCampaign, (discountsPerCampaign) => {
  let totalDiscountAmount = 0;
  Object.keys(discountsPerCampaign).forEach((campaignId) => {
    totalDiscountAmount += discountsPerCampaign[campaignId];
  });
  return totalDiscountAmount;
});

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { piggyRewardClaimed } from "./shoppingCartSlice";
import { piggyAuthApi } from "./api/piggyAuthApi";
import { PiggyGiftcard } from "../../../types/shared/piggy";
import _ from "lodash";
import { toast } from "react-toastify";

type ContactIdentifierScanned = { code: string; timestamp: number };

interface PiggyState {
  anItemHasBeenClaimed: boolean;
  contactIdentifierScan: null | ContactIdentifierScanned;
  contactIdentifierLoggedIn: true | false | null; // false is error, true is ok, null is not yet logged in
  userDoesntWantPiggy: boolean;

  giftcards: PiggyGiftcard[];
  userWrittenPiggyEmail: string | null;
  piggyScanCardDialogMinimized: boolean;
  piggyContactIdentifierDialogMinimized: boolean;
}

const initState: PiggyState = {
  anItemHasBeenClaimed: false,
  contactIdentifierScan: null,
  contactIdentifierLoggedIn: null,
  userDoesntWantPiggy: false,
  userWrittenPiggyEmail: null,
  piggyScanCardDialogMinimized: true,
  piggyContactIdentifierDialogMinimized: true,
  giftcards: [],
};

export const slice = createSlice({
  name: "piggy",
  initialState: initState,
  reducers: {
    piggyRewardsCardScanned: (state, { payload }: PayloadAction<{ code: string; timestamp: number }>) => {
      state.contactIdentifierScan = {
        code: payload.code,
        timestamp: payload.timestamp,
      };
      state.contactIdentifierLoggedIn = null;
    },
    piggyUserLoggedIn: (state, action: PayloadAction<{ code: string }>) => {
      if (state.contactIdentifierScan?.code == action.payload.code) {
        state.contactIdentifierLoggedIn = true;
      }
    },
    piggyUserLogInFailed: (state, action: PayloadAction<{ code: string }>) => {
      if (state.contactIdentifierScan?.code == action.payload.code) {
        state.contactIdentifierLoggedIn = false;
      }
    },
    resetToDefaults: (state) => {
      Object.assign(state, initState);
    },
    userDoesntWantPiggy: (state) => {
      state.userDoesntWantPiggy = true;
    },
    userMinimizedPiggyScanDialog: (state) => {
      state.piggyScanCardDialogMinimized = true;
    },
    piggyLoyaltyDisabled: (state) => {
      state.piggyScanCardDialogMinimized = true;
    },
    piggyScanDialogOpened: (state) => {
      state.piggyScanCardDialogMinimized = false;
    },
    piggyContactIdentifierDialogOpened: (state) => {
      state.piggyContactIdentifierDialogMinimized = false;
    },
    piggyContactIdentifierDialogClosed: (state) => {
      state.piggyContactIdentifierDialogMinimized = true;
    },
    piggyGiftcardAdded: (state, action: PayloadAction<PiggyGiftcard>) => {
      const copy = { ...action.payload };

      const usedGiftcards = _.chain(state.giftcards).keyBy("hash").value();

      if (usedGiftcards[action.payload.hash] == null) {
        toast.success("Giftcard added!");
        state.giftcards.push(copy);
      } else {
        toast.warn("Giftcard already added!");
      }
    },
    piggyGiftcardRemoved: (state, action: PayloadAction<PiggyGiftcard>) => {
      state.giftcards = state.giftcards.filter((giftcard) => giftcard.uuid !== action.payload.uuid);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(piggyRewardClaimed, (state) => {
        state.anItemHasBeenClaimed = true;
      })
      .addMatcher(piggyAuthApi.endpoints.sendEmail.matchFulfilled, (state, action) => {
        state.userWrittenPiggyEmail = action.payload.data.contact_email;
      })
      .addMatcher(piggyAuthApi.endpoints.logout.matchFulfilled, (state) => {
        state.userWrittenPiggyEmail = null;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  piggyRewardsCardScanned,
  resetToDefaults,
  userDoesntWantPiggy,
  userMinimizedPiggyScanDialog,
  piggyScanDialogOpened,
  piggyGiftcardAdded,
  piggyGiftcardRemoved,
  piggyUserLoggedIn,
  piggyUserLogInFailed,
  piggyLoyaltyDisabled,
  piggyContactIdentifierDialogOpened,
  piggyContactIdentifierDialogClosed,
} = slice.actions;

export default slice.reducer;

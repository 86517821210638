import ImageSrc from "../ImageSrc";

import ArticleTraitValue from "./ArticleTraitValue";

export default interface ArticleTrait {
  apiId: string;
  name: string;
  imageSrcs: ImageSrc[];
  values: Record<string, ArticleTraitValue>;
  type: ArticleTraitType;
  useAsQuickFilter: boolean;
  articleField?: string;
  updatedAt: string;
}

export enum ArticleTraitType {
  SINGLE_VALUE = "SINGLE_VALUE",
  MULTIPLE_VALUES = "MULTIPLE_VALUES",
  NUMBER_RANGE = "NUMBER_RANGE",
}

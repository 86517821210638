import { rtkQueryApi } from "./rtkQueryApi";
import {
  Login,
  LoginResponse,
  MWiseAPIReward,
  MWiseCustomerRewardsResponse,
  MWiseSession,
  MWiseSessionResponse,
} from "../../../../types/mwise";

const mwiseEndpoints = rtkQueryApi.enhanceEndpoints({});

export const mwiseApi = mwiseEndpoints.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, Login>({
      query: (login: { card_number: string; password: string }) => {
        return {
          url: `/v5_2/mwise/login`,
          method: "POST",
          body: login,
        };
      },
    }),
    fetchCustomerRewards: builder.query<MWiseAPIReward[], void>({
      query: () => {
        return {
          url: `/v5_2/mwise/customer-rewards`,
          method: "get",
        };
      },
      transformResponse: (response: MWiseCustomerRewardsResponse) => {
        return response.data;
      },
    }),
    session: builder.query<MWiseSession, void>({
      query: () => {
        return {
          url: `/v5_2/mwise/session`,
          method: "get",
        };
      },
      transformResponse: (response: MWiseSessionResponse) => {
        return response.data;
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useLazyFetchCustomerRewardsQuery,
  useFetchCustomerRewardsQuery,
  useSessionQuery,
} = mwiseApi;

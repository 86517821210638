import { configureStore } from "@reduxjs/toolkit";
import globalReducer, { appInitialized } from "./globalSlice";
import userReducer from "./userSlice";
import menuReducer from "./menuSlice";
import menuDataReducer from "./menuDataSlice";
import shoppingCartReducer from "./shoppingCartSlice";
import vouchersV2Reducer from "../vouchersV2/vouchersV2Slice.tsx";
import customFieldsReducer from "./customFieldsSlice";
import menuStateReducer from "./menuStateSlice";
import propposReducer from "./propposSlice";
import accountReducer from "./accountSlice";
import arrangementsReducer from "./arrangements/arrangementsSlice.tsx";
import packagingsReducer from "./packagingsSlice";
import cikamReducer from "./cikamSlice";
import aiDataReducer from "./aiSlice";
// import automationReducer from "./automationSlice";
import traitFilterReducer from "./traitFilterSlice";
import kioskReducer from "./kioskSlice";
import blinkstickReducer from "./blinkstickSlice";
import piggyReducer from "./piggySlice";
import mwiseReducer from "./MWiseSlice";
import devReducer from "./devSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { rtkQueryApi } from "./api/rtkQueryApi";
import userInputSlice from "./userInputSlice";
import { listenerMiddleware } from "./listenerMiddleware";
import UpsellSlice from "./upsellSlice";
import { loadingScreenReducer } from "../../modules/LoadingScreen/loadingScreen.ts";
import { preventKioskTimeoutReducer } from "../../modules/PreventKioskTimeout/preventKioskTimeout.ts";

const store = configureStore({
  reducer: {
    dev: devReducer,
    global: globalReducer,
    aidata: aiDataReducer,
    user: userReducer,
    menu: menuReducer,
    menuData: menuDataReducer,
    shoppingCart: shoppingCartReducer,
    vouchersV2: vouchersV2Reducer,
    customFields: customFieldsReducer,
    menuState: menuStateReducer,
    [rtkQueryApi.reducerPath]: rtkQueryApi.reducer,
    kiosk: kioskReducer,
    blinkstick: blinkstickReducer,
    piggy: piggyReducer,
    mwise: mwiseReducer,
    account: accountReducer,
    proppos: propposReducer,
    traitFilter: traitFilterReducer,
    upsell: UpsellSlice,
    userInput: userInputSlice,
    arrangements: arrangementsReducer,
    packagings: packagingsReducer,
    cikam: cikamReducer,
    loadingScreen: loadingScreenReducer,
    preventKioskTimeout: preventKioskTimeoutReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // ignoredActions: ["menu/setMenu"],
        // ignoredPaths: ["menu.menu"],
      },
    })
      .concat(rtkQueryApi.middleware)
      .prepend(listenerMiddleware.middleware),
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
window.reduxStore = store;
store.dispatch(appInitialized());

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

import OrderArticle, { getTotalPrice } from "../../models/order/OrderArticle";
import Campaign from "../../models/campaigns/Campaign";

export default function calculateXPercentageOffCampaign(campaign: Campaign, orderArticles: OrderArticle[]) {
  let discountAmount = 0;

  if (campaign.Xs?.length === 2) {
    const totalCount = orderArticles.reduce((sum, orderArticle) => {
      return sum + orderArticle.count;
    }, 0);

    if (totalCount >= campaign.Xs[1]) {
      orderArticles.forEach((orderArticle) => {
        discountAmount += (getTotalPrice(orderArticle) * (campaign.Xs?.[0] ?? 0)) / 100;
      });
    }
  }
  return discountAmount;
}

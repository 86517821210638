import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store.tsx";
import { calculateOrderarticlePriceSum } from "../../models/order/OrderArticle.ts";

import { selectPiggyVouchersDiscountValue } from "./selectPiggyVouchersDiscountValue.tsx";

export const selectItemsOrderValue = createSelector(
  [(state: RootState) => state.shoppingCart.items, selectPiggyVouchersDiscountValue],
  (items, piggyDiscountValue) => {
    return calculateOrderarticlePriceSum(items) - piggyDiscountValue;
  }
);

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import store from "../store";
import { createReturnPath } from "../../config";
import { selectAppLanguage } from "../../../../kiosk/components/LanguageSelector/useLanguage.ts";

export const rtkQueryApi = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      headers.set("Accept", "application/json");
      headers.set("Session-MID", store.getState().global.webcode);
      headers.set("Session-Return-Path", window.location.origin + createReturnPath());
      headers.set("Appv5-Build-Date", import.meta.env.__BUILD_DATE__);
      headers.set("Appv5-Build-Release", import.meta.env.__RELEASE__);
      const locale = selectAppLanguage(store.getState());
      if (locale) {
        headers.set("Session-Locale", locale.toString());
      }
      if (store.getState().shoppingCart.serverSideShoppingCart?.uuid) {
        headers.set("Shopping-Cart-Uuid", store.getState().shoppingCart.serverSideShoppingCart?.uuid ?? "");
      }
      return headers;
    },
    responseHandler: (response) => {
      if (response.status == 401) {
        response.json().then((json) => {
          if (json && typeof json === "object" && json?.message == "Ip not allowed") {
            window.location.href = "/ipnotallowed";
          }
        });
      }
      return response.json();
    },
  }),
  endpoints: () => ({}),
});

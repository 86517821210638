import Article, { ArticleType } from "../menu/Article";
import OrderOptionGroup, { isOrderOptionGroupValid } from "./OrderOptionGroup";
import { getArticleById, getOptionGroupsByArticleId } from "../menu/Menu";
import { v4 as uuidv4 } from "uuid";
import roundTwoDecimals from "../../helpers/roundTwoDecimals";
import EanCode from "../menu/EanCode";
import store from "../../redux/store";
import { selectOptionGroupsMap } from "../../redux/selectors/selectOptionGroupsMap";
import Articlegroup from "../menu/Articlegroup";
import _ from "lodash";
import { getPropertyOfOptionGroup } from "../menu/OptionGroup.ts";

export enum OrderArticleOrigin {
  // default
  MENU = "MENU",
  // SHARED_SHOPPING_CART = "SHARED_SHOPPING_CART",
  // piggy rewards
  PIGGY = "PIGGY",
  // items scanned with proppos
  PROPPOS = "PROPPOS",
  // Automatically added by the system (configured in backoffice)
  SYSTEM = "SYSTEM",
}

export interface OrderArticleAnalytics {
  articlegroup?: Articlegroup;
  upsellType?:
    | "Freeoption"
    | "OneTimeUpsell"
    | "UpsellNoFood"
    | "UpsellNoDrinks"
    | "Product"
    | "MenuUpsell"
    | "AiUpsell"
    | "AiUpsellCart"
    | "UpsellOrderingDisabled"
    | "Upsells";
  position?: number;
  /*
   * either a menuid or a productid
   */
  upsellSource?: string;
  upsellId?: number;
}

export default interface OrderArticle extends OrderArticleAnalytics {
  arrangement_credit_cost?: "outside_arrangements" | "no_cost" | number;
  article: Article;
  extraOrderArticles?: OrderArticle[];
  count: number;
  added_origin: OrderArticleOrigin;
  orderOptionGroups: OrderOptionGroup[];
  remark: string | null;
  uuid: string;
  editable: boolean;
  isUpselled: boolean;
  sendToApi: boolean;
  type: ArticleType;
  eanCode?: EanCode | null;
}

export type IncludeArticleTypes = { [key in ArticleType]?: boolean } | undefined;

export function findInvalidOrderOptionGroup(orderArticle: OrderArticle): OrderOptionGroup | null {
  let invalidOrderOptionGroup = null;
  for (const orderOptionGroup of orderArticle.orderOptionGroups) {
    if (orderOptionGroup.orderArticles.length === 0) {
      continue;
    }
    if (!isOrderOptionGroupValid(orderOptionGroup, orderArticle.article)) {
      return orderOptionGroup;
    }

    for (const orderArticle of orderOptionGroup.orderArticles) {
      if (orderArticle.count === 0) {
        continue;
      }
      invalidOrderOptionGroup = findInvalidOrderOptionGroup(orderArticle);
      if (invalidOrderOptionGroup) {
        return invalidOrderOptionGroup;
      }
    }
  }

  return null;
}

export function getTotalPrice(
  orderArticle: OrderArticle,
  count: number | null = null,
  includeArticleTypes: IncludeArticleTypes = undefined
): number {
  if (includeArticleTypes && !includeArticleTypes[orderArticle.type]) {
    return 0;
  }
  let total = 0;
  total += (count ?? orderArticle.count) * orderArticle.article.price;
  for (const orderOptionGroup of orderArticle.orderOptionGroups) {
    if (orderOptionGroup.optionGroup.countable) {
      for (const subOrderArticle of orderOptionGroup.orderArticles) {
        total += (count ?? orderArticle.count) * getTotalPrice(subOrderArticle, null, includeArticleTypes);
      }
    } else {
      for (const subOrderArticle of orderOptionGroup.orderArticles) {
        total += (count ?? orderArticle.count) * getTotalPrice(subOrderArticle, null, includeArticleTypes);
      }
    }
  }

  if (orderArticle.extraOrderArticles) {
    orderArticle.extraOrderArticles.forEach((extraOrderArticle) => {
      total += getTotalPrice(extraOrderArticle, null, includeArticleTypes) * (count ?? orderArticle.count);
    });
  }
  return roundTwoDecimals(total);
}

export function getExtraOrderArticlesPrice(orderArticle: OrderArticle, count: number | null = null) {
  return (
    orderArticle.extraOrderArticles?.reduce((total, extraOrderArticle) => {
      total += getTotalPrice(extraOrderArticle) * (count ?? orderArticle.count);
      return total;
    }, 0) ?? 0
  );
}

export function initOrderArticle(
  articlesMap: Record<string, Article>,
  article: Article,
  count: number,
  idPrefix = "",
  editable = true,
  isUpsell = false,
  isUpselled = false,
  addedOrigin: OrderArticleOrigin = OrderArticleOrigin.MENU,
  type: ArticleType = ArticleType.Regular,
  eanCode: EanCode | null = null,
  upsellType?: OrderArticleAnalytics["upsellType"],
  articlegroup?: OrderArticleAnalytics["articlegroup"],
  position?: OrderArticleAnalytics["position"],
  upsellSource?: OrderArticleAnalytics["upsellSource"],
  uuid?: string,
  articleIdsPath: string[] = [],
  upsellId?: OrderArticleAnalytics["upsellId"]
): OrderArticle {
  const optionGroupsMap = selectOptionGroupsMap(store.getState());
  const extraOrderArticles: OrderArticle[] = [];

  if (article.addExtraArticleIds) {
    article.addExtraArticleIds.forEach(({ articleId, type }) => {
      const article = getArticleById(articlesMap, articleId);
      if (article) {
        extraOrderArticles.push(
          initOrderArticle(articlesMap, article, 1, "", true, false, false, OrderArticleOrigin.MENU, type)
        );
      }
    });
  }

  const optionGroups = (getOptionGroupsByArticleId(articlesMap, optionGroupsMap, article?.id)
    ?.map((optionGroup) => {
      if (isUpsell && getPropertyOfOptionGroup(optionGroup, article, "minCount") === 0) {
        return null;
      }

      let defaultCounts = 0;
      const maxCount = getPropertyOfOptionGroup(optionGroup, article, "maxCount");

      return {
        id: idPrefix + "Opt" + optionGroup.id,
        optionGroup,
        orderArticles: (optionGroup.optionIds
          .map((optionId) => getArticleById(articlesMap, optionId))
          .filter((subArticle) => subArticle) as Article[])
          .filter((subArticle) => !_.includes(articleIdsPath, subArticle.id))
          .map((subArticle: Article) => {
            const defaultCount = Math.min(subArticle.defaultCount ?? 0, maxCount - defaultCounts);
            defaultCounts += defaultCount;
            return initOrderArticle(
              articlesMap,
              subArticle,
              defaultCount,
              idPrefix + "Opt" + optionGroup.id + "-Art" + subArticle.id + "-",
              true,
              optionGroup.isUpsell,
              false,
              OrderArticleOrigin.MENU,
              ArticleType.Regular,
              null,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              [subArticle.id, ...articleIdsPath]
            );
          }),
      };
    })
    .filter((orderOptionGroup: OrderOptionGroup | null) => {
      return orderOptionGroup && orderOptionGroup.orderArticles.length > 0;
    }) as OrderOptionGroup[]).sort((orderOptionGroupA, orderOptionGroupB) => {
    const sortKeyA = getPropertyOfOptionGroup(orderOptionGroupA.optionGroup, article, "sortKey");

    const sortKeyB = getPropertyOfOptionGroup(orderOptionGroupB.optionGroup, article, "sortKey");
    return sortKeyA - sortKeyB;
  });
  return {
    article,
    extraOrderArticles,
    count,
    added_origin: addedOrigin,
    orderOptionGroups: optionGroups ?? [],
    remark: "",
    sendToApi: true,
    uuid: uuid ?? uuidv4(),
    editable,
    isUpselled,
    type,
    eanCode,
    upsellType,
    articlegroup,
    position,
    upsellSource,
    upsellId,
  };
}

export function orderArticlesAreEqual(orderArticle: OrderArticle, other: OrderArticle, isOption = false) {
  if (orderArticle.count === 0 && other.count === 0) {
    return true;
  }
  if (isOption) {
    if (orderArticle.count !== other.count) {
      return false;
    }
  }
  if (orderArticle.added_origin !== other.added_origin) {
    return false;
  }
  if (
    orderArticle.article.id === other.article.id &&
    orderArticle.article.price === other.article.price &&
    orderArticle.article.name === other.article.name
  ) {
    for (let i = 0; i < orderArticle.orderOptionGroups.length; i++) {
      if (!orderOptionGroupsAreEqual(orderArticle.orderOptionGroups[i], other.orderOptionGroups[i])) {
        return false;
      }
    }
    return true;
  }
  return false;
}

function orderOptionGroupsAreEqual(orderOptionGroup: OrderOptionGroup | null, other: OrderOptionGroup | null) {
  if (orderOptionGroup == null || other == null) {
    return false;
  }
  for (let i = 0; i < orderOptionGroup.orderArticles.length; i++) {
    if (!orderArticlesAreEqual(orderOptionGroup.orderArticles[i], other.orderArticles[i], true)) {
      return false;
    }
  }
  return true;
}

export function isOrderArticleValidAfterSum(orderArticle: OrderArticle, count: number) {
  if (orderArticle.count + count < 0) {
    return false;
  }
  return (
    (orderArticle.article.maxCount == null || orderArticle.count + count <= orderArticle.article.maxCount) &&
    (orderArticle.article.minCount == null || orderArticle.count + count >= orderArticle.article.minCount)
  );
}

export function* recurOrderArticleOptions(orderArticle: OrderArticle): Generator<OrderArticle> {
  for (const optionGroup of orderArticle.orderOptionGroups) {
    for (const option of optionGroup.orderArticles) {
      yield option;
      for (const subOption of recurOrderArticleOptions(option)) {
        yield subOption;
      }
    }
  }
}

export function shouldShowOrderArticleSelector(orderArticle: OrderArticle): boolean {
  return (
    Boolean(orderArticle.orderOptionGroups.length) &&
    Boolean(orderArticle.orderOptionGroups.find((optionGroup) => Boolean(optionGroup.orderArticles.length)))
  );
}

export function calculateOrderarticlePriceSum(
  shoppingCartItems: OrderArticle[],
  includeArticleTypes: IncludeArticleTypes = undefined
) {
  return shoppingCartItems.reduce((sum, item: OrderArticle) => {
    return sum + getTotalPrice(item, null, includeArticleTypes);
  }, 0);
}

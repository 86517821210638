export default interface ImageSrc {
  uuid: string;
  extension: string;
  size?: number;
  url?: string;
}

export function getImageFilePath(imageSrc: ImageSrc, size?: number) {
  if (imageSrc.url) {
    return imageSrc.url;
  }

  let doResize: boolean = true;
  if (imageSrc.extension === "gif") {
    doResize = false;
  }

  let url = import.meta.env.VITE_AZURE_STORAGE + "image_uuids/" + imageSrc.uuid + "/";

  if (doResize) {
    url += size ? String(size) + "/" : "600/";
  }
  url += imageSrc.uuid + "." + imageSrc.extension;

  return url;
}

export function parseImgUuid(imgUuid: string): ImageSrc {
  if (imgUuid.startsWith("https")) {
    return { uuid: "", extension: imgUuid.split(/[#?]/)?.[0]?.split(".")?.pop()?.trim() ?? "", url: imgUuid };
  }
  const extension = imgUuid.replace(/^[^.]*\./, "");
  const uuid = imgUuid.replace(/\.[^.]*$/, "");
  return { uuid, extension };
}

import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store.tsx";
import { selectItemsOrderValue } from "./selectItemsOrderValue.tsx";
import { selectArticlesMap } from "./selectArticlesMap.ts";
import { selectDepositOrderValue } from "./selectDepositOrderValue.ts";
import { selectOrderArticlesFromVouchersV2 } from "../../vouchersV2/selectors/selectOrderArticlesFromVouchersV2.tsx";
import OrderArticle, { initOrderArticle, OrderArticleOrigin } from "../../models/order/OrderArticle.ts";
import { VoucherType } from "../../vouchers/Voucher.ts";
import roundTwoDecimals from "../../helpers/roundTwoDecimals.ts";
import {
  calculateDiscountAmountForProduct,
  calculateDiscountPercentageForProduct,
} from "../../useVoucherOrderItems.ts";

export const selectVoucherOrderItems = createSelector(
  [
    (state: RootState) => state.shoppingCart.vouchers,
    (state: RootState) => state.shoppingCart.items,
    selectItemsOrderValue,
    selectArticlesMap,
    selectDepositOrderValue,
    selectOrderArticlesFromVouchersV2,
  ],
  (vouchers, items, itemsOrderValue, articlesMap, depositOrderValue, orderArticleVouchersV2) => {
    const amount = itemsOrderValue - depositOrderValue;
    const orderArticlesMap: { [articleId: string]: OrderArticle[] } = {};
    items.forEach((item) => {
      if (!orderArticlesMap[item.article.id]) {
        orderArticlesMap[item.article.id] = [];
      }
      orderArticlesMap[item.article.id].push(item);
    });
    const orderArticles: OrderArticle[] = [];
    vouchers.forEach((voucher) => {
      if (voucher.voucherType === VoucherType.AMOUNT_OFF_TOTAL) {
        voucher.articles.forEach((article) => {
          orderArticles.push(
            initOrderArticle(
              articlesMap,
              {
                ...article,
                price: -Math.min(amount, voucher.amount),
              },
              1,
              "",
              false,
              false,
              false,
              OrderArticleOrigin.SYSTEM
            )
          );
        });
      } else if (voucher.voucherType === VoucherType.PERCENTAGE_OFF_TOTAL) {
        voucher.articles.forEach((article) => {
          orderArticles.push(
            initOrderArticle(
              articlesMap,
              {
                ...article,
                price: roundTwoDecimals(-amount * voucher.amount, true),
              },
              1,
              "",
              false,
              false,
              false,
              OrderArticleOrigin.SYSTEM
            )
          );
        });
      } else if (voucher.voucherType === VoucherType.AMOUNT_OFF_PRODUCT) {
        calculateDiscountAmountForProduct(articlesMap, voucher, orderArticlesMap, orderArticles);
      } else if (voucher.voucherType === VoucherType.PERCENTAGE_OFF_PRODUCT) {
        calculateDiscountPercentageForProduct(articlesMap, voucher, orderArticlesMap, orderArticles);
      } else if (voucher.voucherType === VoucherType.ADD_DISCOUNTED_PRODUCT) {
        voucher.articles.forEach((article) => {
          orderArticles.push(
            initOrderArticle(
              articlesMap,
              {
                ...article,
                // price: roundTwoDecimals(-shoppingCartAmount * voucher.amount, true),
              },
              1,
              "",
              false,
              false,
              false,
              OrderArticleOrigin.SYSTEM
            )
          );
        });
      }
    });
    return [...orderArticles, ...orderArticleVouchersV2];
  }
);

import { useCallback } from "react";
import { httpGet } from "./http";

import { setAppStyling, setSalesarea, setServerTimestamp } from "../redux/globalSlice";
import { defaultCurrency, setCurrency } from "../redux/menuSlice";
import store, { useAppDispatch } from "../redux/store";
import useParseCurrencyRates from "./parseApis/useParseCurrencyRates";
import { currencies } from "../models/currencies/Currency";

export default function useSalesareaData() {
  const dispatch = useAppDispatch();
  // const parseApiMenu = useParseApiMenu();
  const parseCurrencyRates = useParseCurrencyRates();

  const getSalesareaData = useCallback(() => {
    return httpGet("/v5_2/qr/salesarea-fetch", null, { showSpinner: false, showToastError: false })
      .then((response: any) => {
        setTimeout(() => {
          if (response.data.data.salesarea.custom_data) {
            if (response.data.data.salesarea.custom_data === "[]") {
              response.data.data.salesarea.custom_data = {};
            } else {
              response.data.data.salesarea.custom_data = JSON.parse(response.data.data.salesarea.custom_data);
            }
          } else {
            response.data.data.salesarea.custom_data = {};
          }
          if (
            response.data.data.salesarea.custom_data.kiosk &&
            response.data.data.salesarea.custom_data.kiosk.enable_product_barcode_scanner === undefined
          ) {
            response.data.data.salesarea.custom_data.kiosk.enable_product_barcode_scanner = true;
          }

          // response.data.data.salesarea.type_of_shopping_cart_button = "SMALL";
          // response.data.data.salesarea.orientation_of_categories = "HORIZONTAL";
          if (JSON.stringify(response.data.data.salesarea) !== JSON.stringify(store.getState().global.salesarea)) {
            dispatch(setSalesarea(response.data.data.salesarea));
          }

          const currency = currencies[response.data.data.salesarea?.valuta] ?? defaultCurrency;
          if (currency !== store.getState().menu.currency) {
            dispatch(setCurrency(currency));
          }

          try {
            dispatch(setServerTimestamp(response.data.timestamp));
          } catch (err) {
            return err;
          }
          const appStyling = {
            "JS-QrTheme": {},
            "JS-QrGlobal": {},
          };
          const customData = response.data.data.salesarea?.custom_data;

          if (customData?.["JS-QrTheme"]) {
            appStyling["JS-QrTheme"] = customData?.["JS-QrTheme"];
          }
          if (customData?.["JS-QrGlobal"]) {
            appStyling["JS-QrGlobal"] = customData?.["JS-QrGlobal"];
          }
          if (JSON.stringify(appStyling) !== JSON.stringify(store.getState().global.appStyling)) {
            dispatch(setAppStyling(appStyling));
          }

          parseCurrencyRates(response.data.data.currency_rates);
        }, 1);
      })
      .catch((e) => {
        if (e?.response?.data?.message === "Ip not allowed") {
          window.location.href = "/ipnotallowed";
        }
      });
  }, [dispatch, parseCurrencyRates]);

  return { getSalesareaData };
}

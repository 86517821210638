import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Articlegroup from "../models/menu/Articlegroup";
import CurrencyRate from "../models/currencies/CurrencyRate";
import Currency from "../models/currencies/Currency";
import { RootState } from "./store";
import { selectSupergroupsMap } from "./selectors/selectSupergroupsMap";

interface MenuState {
  userSelectedSupergroupId: string;

  selectedArticlegroupIds: Record<string, boolean>;

  selectedArticlegroup: Articlegroup | null;
  priceKey: string;
  currencyRates: Record<string, CurrencyRate>;
  currency: Currency;
}

export const defaultCurrency: Currency = { id: "EUR", name: "EURO", symbol: "€" };

const initState: MenuState = {
  currencyRates: {},
  userSelectedSupergroupId: "",
  selectedArticlegroupIds: {},
  selectedArticlegroup: null,
  priceKey: "",
  currency: defaultCurrency,
};

export const menuSlice = createSlice({
  name: "menu",
  initialState: initState,
  reducers: {
    setSelectedSupergroupId: (state, action) => {
      state.userSelectedSupergroupId = action.payload;
    },
    setSelectedArticlegroupIds: (state, action) => {
      state.selectedArticlegroupIds = action.payload;
    },
    setSelectedArticlegroup: (
      state,
      action: PayloadAction<{ articlegroup: Articlegroup | null; articlegroupsMap: Record<string, Articlegroup> }>
    ) => {
      state.selectedArticlegroup = action.payload.articlegroup;
      const map: Record<string, boolean> = {};
      const articlegroupsMap = action.payload.articlegroupsMap;
      Object.keys(articlegroupsMap).forEach((id) => {
        map[id] = false;
      });
      if (action.payload.articlegroup?.id) {
        map[action.payload.articlegroup.id] = true;
      }
      state.selectedArticlegroupIds = map;
    },
    setPriceKey: (state, action) => {
      state.priceKey = action.payload;
    },
    setCurrencyRates: (state, action: PayloadAction<Record<string, CurrencyRate>>) => {
      state.currencyRates = action.payload;
    },
    setCurrency: (state, action: PayloadAction<Currency>) => {
      state.currency = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSelectedSupergroupId,
  setSelectedArticlegroupIds,
  setSelectedArticlegroup,
  setPriceKey,
  setCurrencyRates,
  setCurrency,
} = menuSlice.actions;

export const selectSelectedSupergroupId = createSelector(
  [(state: RootState) => state.menu.userSelectedSupergroupId, selectSupergroupsMap],
  (userSelectedSupergroupId, supergroupsMap) => {
    if (userSelectedSupergroupId) {
      return userSelectedSupergroupId;
    } else {
      return Object.values(supergroupsMap).sort((a, b) => a.sortKey - b.sortKey)[0]?.id ?? "";
    }
  }
);
export default menuSlice.reducer;

import Voucher from "./vouchers/Voucher";
import OrderArticle, { getTotalPrice, initOrderArticle, OrderArticleOrigin } from "./models/order/OrderArticle";
import roundTwoDecimals from "./helpers/roundTwoDecimals";
import Article from "./models/menu/Article";

export function calculateDiscountPercentageForProduct(
  articlesMap: Record<string, Article>,
  voucher: Voucher,
  orderArticlesMap: { [articleId: string]: OrderArticle[] },
  orderArticles: OrderArticle[]
) {
  let numberOfItemsInShoppingCart = 0;
  let amountInShoppingCart = 0;
  const maxChooseItems = voucher.voucherDef?.maxChooseItems ?? 0;
  const articleIds = JSON.parse(voucher.voucherDef.includedProducts_JSON ?? "[]");
  articleIds.forEach((articleId: number) => {
    if (orderArticlesMap[articleId]) {
      orderArticlesMap[articleId].forEach((orderArticle) => {
        for (
          let i = 0;
          i < orderArticle.count && (numberOfItemsInShoppingCart < maxChooseItems || maxChooseItems === 0);
          i++
        ) {
          numberOfItemsInShoppingCart++;
          amountInShoppingCart += getTotalPrice(orderArticle, 1);
        }
      });
    }
  });

  voucher.articles.forEach((article) => {
    orderArticles.push(
      initOrderArticle(
        articlesMap,
        {
          ...article,
          price: roundTwoDecimals(-amountInShoppingCart * voucher.amount, true),
        },
        1,
        "",
        false,
        false,
        false,
        OrderArticleOrigin.SYSTEM
      )
    );
  });
}

export function calculateDiscountAmountForProduct(
  articlesMap: Record<string, Article>,
  voucher: Voucher,
  orderArticlesMap: { [articleId: string]: OrderArticle[] },
  orderArticles: OrderArticle[]
) {
  let numberOfItemsInShoppingCart = 0;
  let amountInShoppingCart = 0;
  const maxChooseItems = voucher.voucherDef?.maxChooseItems ?? 0;
  const articleIds = JSON.parse(voucher.voucherDef.includedProducts_JSON ?? "[]");
  articleIds.forEach((articleId: number) => {
    if (orderArticlesMap[articleId]) {
      orderArticlesMap[articleId].forEach((orderArticle) => {
        for (
          let i = 0;
          i < orderArticle.count && (numberOfItemsInShoppingCart < maxChooseItems || maxChooseItems === 0);
          i++
        ) {
          numberOfItemsInShoppingCart++;
          amountInShoppingCart += getTotalPrice(orderArticle, 1);
        }
      });
    }
  });

  voucher.articles.forEach((article) => {
    orderArticles.push(
      initOrderArticle(
        articlesMap,
        {
          ...article,
          price: roundTwoDecimals(-voucher.amount * numberOfItemsInShoppingCart, true),
        },
        1,
        "",
        false,
        false,
        false,
        OrderArticleOrigin.SYSTEM
      )
    );
  });
  // console.log(numberOfItemsInShoppingCart, voucher, maxChooseItems);
  // console.log(voucher, orderArticlesMap, orderArticles);
}

import { rtkQueryApi } from "./rtkQueryApi";
import _ from "lodash";
import store, { useAppDispatch } from "../store";
import { selectArticlesMap } from "../selectors/selectArticlesMap";

import {
  ClaimRewardResponse,
  GiftcardResponse,
  ListVouchersResponse,
  LoyaltyProgram,
  RewardsResponse,
  TransactionHistoryResponse,
} from "../../../../types/shared/piggy";

const piggyEndpoints = rtkQueryApi.enhanceEndpoints({
  addTagTypes: ["piggy-balance", "piggy-rewards", "piggy-vouchers"],
});

export const piggyApi = piggyEndpoints.injectEndpoints({
  endpoints: (builder) => ({
    fetchLoyaltyProgram: builder.query<LoyaltyProgram, void>({
      query: () => {
        return {
          url: `/v5_2/piggy/loyalty-get-program`,
          method: "GET",
        };
      },
    }),
    fetchPointsBalance: builder.query<number, void | { contact_email: string }>({
      query: (args) => {
        return {
          url: `/v5_2/piggy/contact-balance`,
          method: "GET",
          ...(args ?? {}),
        };
      },
      transformResponse: (response: { data: { balance: number } }) => response.data.balance,
      providesTags: ["piggy-balance"],
    }),
    fetchPiggyTransactionHistory: builder.query<TransactionHistoryResponse, string | void>({
      query: (contact_email?) => {
        if (contact_email) {
          return {
            url: `/v5_2/piggy/loyalty-get-transactions?email=${contact_email}`,
            method: "GET",
          };
        }
        return {
          url: `/v5_2/piggy/loyalty-get-transactions`,
          method: "GET",
        };
      },
    }),
    fetchShopRewards: builder.query<RewardsResponse["data"], number>({
      query: (salesareaId) => {
        return {
          url: `/v5_2/piggy/shop-rewards?sales_area_id=${salesareaId}`,
          method: "GET",
        };
      },
      transformResponse: (response: RewardsResponse) => {
        const articlesMap = selectArticlesMap(store.getState());
        const jamezzArticleIds = _.map(articlesMap, (s) => String(s.id));
        return response.data.filter((piggyReward) => {
          return _.includes(jamezzArticleIds, piggyReward.artikelid);
        });
      },
    }),
    fetchRewards: builder.query<RewardsResponse, void>({
      query: () => {
        return {
          url: `/v5_2/piggy/loyalty-rewards`,
          method: "GET",
        };
      },
      // can't get individual items anyway,
      // so even though it's a list it doesn't really matter ever.
      // maybe later if it ever makes sense
      providesTags: [{ type: "piggy-rewards", id: "LIST" }],
      transformResponse: (response: RewardsResponse) => {
        const articlesMap = selectArticlesMap(store.getState());
        const jamezzArticleIds = _.map(articlesMap, (s) => String(s.id));
        response.data = response.data.filter((piggyReward) => {
          return _.includes(jamezzArticleIds, piggyReward.artikelid);
        });
        return response;
      },
    }),
    claimReward: builder.mutation<ClaimRewardResponse, string>({
      query: (reward_uuid: string) => {
        return {
          url: `/v5_2/piggy/loyalty-claim-reward`,
          method: "POST",
          body: {
            reward_uuid,
          },
        };
      },
      async onQueryStarted(reward_uuid, { dispatch, queryFulfilled }) {
        try {
          const { data: response } = await queryFulfilled;
          if (response.data.has_been_collected) {
            dispatch(
              piggyApi.util.updateQueryData("fetchPointsBalance", undefined, (draft) => {
                return draft + response.data.credits;
              })
            );
          }
        } catch (e) {}
      },
    }),
    fetchGiftcard: builder.query<GiftcardResponse, string>({
      query: (giftcardHash) => {
        return {
          url: `/v5_2/piggy/giftcards/find-one?giftcard_hash=${giftcardHash}`,
          method: "GET",
        };
      },
    }),

    listVouchers: builder.query<ListVouchersResponse, { contactIdentifier: string; status?: string; page: number }>({
      query: ({ contactIdentifier, status, page }) => {
        const search = new URLSearchParams();
        if (status) {
          search.set("status", status);
        }
        search.set("code", contactIdentifier);
        search.set("page", String(page));
        return {
          url: `/v5_2/piggy/vouchers/list?` + search.toString(),
          method: "GET",
        };
      },
      keepUnusedDataFor: 0,
    }),
  }),
});

export function useInvalidatePiggyCache() {
  const dispatch = useAppDispatch();
  return () => {
    dispatch(piggyApi.util.invalidateTags(["piggy-balance", "piggy-vouchers"]));
  };
}

export const {
  useFetchPointsBalanceQuery,

  useLazyFetchPiggyTransactionHistoryQuery,
  useFetchRewardsQuery,
  useFetchShopRewardsQuery,
  useClaimRewardMutation,
  useLazyFetchLoyaltyProgramQuery,
  useFetchLoyaltyProgramQuery,
  useListVouchersQuery,
  useLazyListVouchersQuery,
  useLazyFetchGiftcardQuery,
} = piggyApi;

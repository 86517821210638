import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store.tsx";
import OrderArticle, { calculateOrderarticlePriceSum } from "../../models/order/OrderArticle.ts";
import { ArticleType } from "../../models/menu/Article.ts";

function calculateRecursiveDepositValue(orderArticle: OrderArticle, count: number = 1) {
  let depositOrderValue = 0;
  const depositArticleIds: string[] = [];
  orderArticle.article.addExtraArticleIds?.forEach(({ articleId, type }) => {
    if (type == ArticleType.Deposit) {
      depositArticleIds.push(articleId);
    }
  });

  orderArticle.extraOrderArticles?.forEach((extraOrderArticle) => {
    const index = depositArticleIds.findIndex((depositId) => {
      return extraOrderArticle.article.id == depositId;
    });

    if (index >= 0) {
      depositArticleIds.splice(index, 1);
      depositOrderValue += calculateOrderarticlePriceSum([extraOrderArticle]);
    }
  });

  orderArticle.orderOptionGroups.forEach((orderOptionGroup) => {
    orderOptionGroup.orderArticles.filter((optionOrderArticle) => {
      if (optionOrderArticle.count > 0) {
        depositOrderValue += calculateRecursiveDepositValue(optionOrderArticle, orderArticle.count);
      }
    });
  });
  return depositOrderValue * count;
}

export const selectDepositOrderValue = createSelector([(state: RootState) => state.shoppingCart.items], (items) => {
  let depositOrderValue = 0;
  items.forEach((orderArticle) => {
    depositOrderValue += calculateRecursiveDepositValue(orderArticle);
  });
  return depositOrderValue;
});

import { startAppListening } from "../listenerMiddleware";
import store from "../store";
import CustomField, { OrderCustomField } from "../../../../types/shared/CustomField";
import { selectCustomFields } from "../selectors/selectCustomFields";
import {
  customFieldsMasterCode,
  customFieldsSlice,
  setOrderCustomField,
  shouldRememberCustomField,
} from "../customFieldsSlice";
import isQr from "../../helpers/isQr";
import _ from "lodash";
import { globalSlice } from "../globalSlice.tsx";

export function customFieldsListener() {
  startAppListening({
    predicate: (__, currentState, previousState) => {
      return __.type === globalSlice.actions.setSessionState.type;
    },
    effect: (action, { dispatch, getState }) => {
      const session = action.payload;
      if (session?.adres && session?.plaats && session?.postcode) {
        const addressCustomFields: CustomField[] = customFieldsMasterCode(session);

        addressCustomFields.forEach((customField) => {
          if (!(customField.name in getState().customFields.orderCustomFields)) {
            dispatch(
              setOrderCustomField({
                customFieldName: customField.name,
                value: customField.defaultValue,
              })
            );
          }
        });
      }
    },
  });
}

export function customFields2Listener() {
  startAppListening({
    predicate: (__, currentState, previousState) => {
      return __.type === "global/setSalesarea";
    },
    effect: (action, { dispatch, getState }) => {
      const salesareaId = action.payload.id;
      if (isQr()) {
        const orderCustomFieldsText = localStorage.getItem(`V5.orderCustomFields.${salesareaId}`);
        if (orderCustomFieldsText) {
          try {
            // only load those who have remember = true
            // for example, if settings in backoffice change over time.
            // Where previously remember=true, later remember=false
            const object: Record<string, OrderCustomField> = JSON.parse(orderCustomFieldsText);
            const customFields = selectCustomFields(getState());
            // const orderCustomFields = store.getState().customFields.orderCustomFields;
            _.forIn(object, (orderCustomField, name) => {
              const relatedCustomfield = customFields.find((customField) => customField.name === name);
              if (!relatedCustomfield) {
                return false;
              }
              if (
                (relatedCustomfield &&
                  relatedCustomfield.remember &&
                  shouldRememberCustomField[relatedCustomfield.type]) ||
                window.location.pathname.includes("return")
              ) {
                if (orderCustomField.value) {
                  store.dispatch(customFieldsSlice.actions.setOrderCustomField({ orderCustomField }));
                } else {
                  store.dispatch(customFieldsSlice.actions.clearOrderCustomField({ orderCustomField }));
                }
              }
            });
          } catch (e) {
            console.warn("Was unable to load orderCustomFields from localStorage");
          }
        }
      }
    },
  });
}

import { AnyAction, createSlice, ThunkAction } from "@reduxjs/toolkit";
import { orderSucceeded } from "./shoppingCartSlice";
import { setSalesarea } from "./globalSlice";
import isQr from "../helpers/isQr";
import { RootState } from "./store";
import { resetKiosk } from "./kioskSlice";

type UpsellState = {
  oneTimeUpsellShown: boolean;
};

const initialState: UpsellState = {
  oneTimeUpsellShown: false,
};

export const upsellSlice = createSlice({
  name: "upsell",
  initialState,
  reducers: {
    oneTimeUpsellShown: (state) => {
      state.oneTimeUpsellShown = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(orderSucceeded, (state) => {
        state.oneTimeUpsellShown = false;
      })
      .addCase(setSalesarea, (state, { payload: { id: salesareaId } }) => {
        if (isQr() && sessionStorage.getItem(`V5.${salesareaId}.oneTimeUpsellShown`) === "true") {
          state.oneTimeUpsellShown = true;
        }
      })
      .addCase(resetKiosk, (state) => {
        state.oneTimeUpsellShown = false;
      });
  },
});

export const oneTimeUpsellShown = (): ThunkAction<void, RootState, unknown, AnyAction> => (dispatch, getState) => {
  const salesareaId = getState().global.salesarea?.id as number;
  if (isQr()) {
    sessionStorage.setItem(`V5.${salesareaId}.oneTimeUpsellShown`, "true");
  }

  dispatch(upsellSlice.actions.oneTimeUpsellShown());
};

export default upsellSlice.reducer;

import { createSelector, createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import OrderArticle, {
  initOrderArticle,
  OrderArticleOrigin,
  orderArticlesAreEqual,
} from "../../../global/utils/models/order/OrderArticle";
import Voucher from "../vouchers/Voucher";
import JamezzPaymentMethod from "../models/JamezzPayments/JamezzPaymentMethod";
import OrderOptionGroup from "../models/order/OrderOptionGroup";
import Article from "../models/menu/Article";
import { appInitialized, setSalesarea } from "./globalSlice";
import _ from "lodash";
import { OutstandingBalanceResponse } from "../api/scanAndPay/checkOutstandingBalance";
import { SharedShoppingCart, ShoppingCart } from "../sharedShoppingCart/processSharedShoppingCart";
import { RootState } from "./store";
import { selectArticlesMap } from "./selectors/selectArticlesMap";
import { PiggyReward } from "../../../types/shared/piggy";
import { ShoppingCartConfirmation } from "./api/shoppingCartApi";
import { selectOrderArticlesFromVouchersV2 } from "../vouchersV2/selectors/selectOrderArticlesFromVouchersV2.tsx";

const serverSideShoppingCartJson = localStorage.getItem("V5.shoppingCart.serverSideShoppingCart");
let serverSideShoppingCart: ShoppingCart | null = null;
if (serverSideShoppingCartJson != null) {
  serverSideShoppingCart = JSON.parse(serverSideShoppingCartJson) as ShoppingCart;
}

const jamezzPaymentMethodJson = localStorage.getItem("V5.shoppingCart.jamezzPaymentMethod");
let jamezzPaymentMethod: JamezzPaymentMethod = { payMethod: "", payProvider: "", payIssuer: "" };
if (jamezzPaymentMethodJson != null) {
  jamezzPaymentMethod = JSON.parse(jamezzPaymentMethodJson) as JamezzPaymentMethod;
}

const vouchersCartJson = localStorage.getItem("V5.shoppingCart.vouchers");
let vouchersCart: Voucher[] = [];
if (vouchersCartJson != null) {
  vouchersCart = JSON.parse(vouchersCartJson) as Voucher[];
}

interface ShoppingCartState {
  items: OrderArticle[];
  vouchers: Voucher[];
  allergenOrderArticles: Record<string, OrderArticle>;

  discountCardNr: string;
  /**
   * What gets alongside an order to the back-end; determining how the order should be paid for.
   */
  jamezzPaymentMethod: JamezzPaymentMethod;
  invalidOrderOptionGroups: Record<string, boolean>;

  /**
   * Item IDs that should be excluded from orders sent to the POS
   */
  excludedFromOrdering: Article["id"][];

  afterPayData: OutstandingBalanceResponse | null;
  isSharedShoppingCartEnabled: boolean;
  serverSideShoppingCart: ShoppingCart | null;
  sharedShoppingCart: SharedShoppingCart | null;
  sharedShoppingCartTransactionUuidsProcessed: { [uuid: string]: true };

  shoppingCartConfirmation: {
    requestSentForUuid: string | null;
    response: ShoppingCartConfirmation | null;
    waitingForRequestDialogIsOpen: boolean;
  };
}

// function findJamezzArticleForPiggyItem(item: PiggyReward, menu: Menu): Article | undefined {
//   const found = Object.entries(menu.articlesMap).find(([, article]) => String(item.artikelid) === article.id);
//   if (found) {
//     return found[1];
//   }
//   return undefined;
// }

function addItemTransactional(state: Draft<ShoppingCartState>, orderArticle: OrderArticle) {
  const itemIndex = state.items.findIndex((item) => orderArticlesAreEqual(item, orderArticle));
  const item = state.items[itemIndex];

  if (item) {
    item.count += orderArticle.count;
    if (item.count === 0) {
      state.items.splice(itemIndex, 1);
    }
  } else {
    if (orderArticle.count > 0) {
      state.items.push(orderArticle);
    }
  }
  if (!state.isSharedShoppingCartEnabled) {
    localStorage.setItem("V5.shoppingCart.items", JSON.stringify(state.items));
  }
}

export interface ShoppingCartErrors {
  [key: string]: ShoppingCartError;
}

export interface ShoppingCartError {
  id: "ShoppingCart.errors.minOrderValueError" | "ShoppingCart.errors.maxOrderValueError";
  values: any;
}

const initState: ShoppingCartState = {
  items: [],
  vouchers: vouchersCart,
  allergenOrderArticles: {},
  discountCardNr: "",
  excludedFromOrdering: [],
  jamezzPaymentMethod: jamezzPaymentMethod,
  invalidOrderOptionGroups: {},
  afterPayData: null,
  isSharedShoppingCartEnabled: false,
  serverSideShoppingCart: serverSideShoppingCart,
  sharedShoppingCart: null,
  sharedShoppingCartTransactionUuidsProcessed: {},
  shoppingCartConfirmation: { requestSentForUuid: null, response: null, waitingForRequestDialogIsOpen: false },
};

function emptyShoppingCart(state: Draft<ShoppingCartState>) {
  state.items = state.items.filter((item) => state.excludedFromOrdering.includes(item.article.id));
  state.vouchers = [];
  state.discountCardNr = "";
  state.invalidOrderOptionGroups = {};
  state.serverSideShoppingCart = null;
  state.sharedShoppingCart = null;
  state.allergenOrderArticles = {};
  state.excludedFromOrdering = [];

  localStorage.setItem("V5.shoppingCart.serverSideShoppingCart", JSON.stringify(state.serverSideShoppingCart));
  localStorage.setItem("V5.shoppingCart.items", JSON.stringify(state.items));

  localStorage.setItem("V5.shoppingCart.vouchers", JSON.stringify(state.vouchers));
  localStorage.setItem("V5.shoppingCart.allergenOrderArticles", JSON.stringify(state.allergenOrderArticles));
}

export const shoppingCartSlice = createSlice({
  name: "shoppingCart",
  initialState: initState,
  reducers: {
    orderSucceeded: (state) => {
      emptyShoppingCart(state);
    },
    /* @deprecated, use semantically-named reducers instead
     * @see orderSucceeded
     */
    clearShoppingCart: (state) => {
      emptyShoppingCart(state);
    },
    clearSharedShoppingCart: (state) => {
      state.sharedShoppingCart = null;
      state.sharedShoppingCartTransactionUuidsProcessed = {};
    },
    /**
     *
     * @param state
     * @param item
     * @param menu
     * @throws Error
     */
    piggyRewardClaimed: (
      state,
      { payload: { item, orderArticle } }: PayloadAction<{ item: PiggyReward; orderArticle: OrderArticle }>
    ) => {
      state.items.push(orderArticle);
    },
    orderArticlesPushedByUser: (state, action: PayloadAction<OrderArticle[]>) => {
      action.payload.forEach((orderArticle) => {
        addItemTransactional(state, orderArticle);
      });
    },
    orderArticlePushedBySystem: (state, action: PayloadAction<OrderArticle>) => {
      addItemTransactional(state, action.payload);
    },
    orderArticlesReceivedFromTransactions: (state, action: PayloadAction<OrderArticle[]>) => {
      state.items = [];
      action.payload.forEach((orderArticle) => addItemTransactional(state, JSON.parse(JSON.stringify(orderArticle))));
    },
    propposScanReceived: (
      state,
      { payload: { propposProducts } }: PayloadAction<{ propposProducts: OrderArticle[] }>
    ) => {
      propposProducts.forEach((orderArticle) => {
        orderArticle.added_origin = OrderArticleOrigin.PROPPOS;
        state.items.push(orderArticle);
      });
    },
    setVouchers: (state, action: PayloadAction<Voucher[]>) => {
      state.vouchers = action.payload;
      localStorage.setItem("V5.shoppingCart.vouchers", JSON.stringify(state.vouchers));
    },
    voucherRemoved: (state, { payload: voucherToRemove }: PayloadAction<Voucher>) => {
      state.vouchers = state.vouchers.filter((voucher) => voucher.id !== voucherToRemove.id);
      localStorage.setItem("V5.shoppingCart.vouchers", JSON.stringify(state.vouchers));
    },
    setAllergenOrderArticles: (state, action: PayloadAction<Record<string, OrderArticle>>) => {
      state.allergenOrderArticles = action.payload;
      localStorage.setItem("V5.shoppingCart.allergenOrderArticles", JSON.stringify(state.allergenOrderArticles));
    },
    removeAllergenOrderArticle: (state, action: PayloadAction<{ allergenId: string }>) => {
      delete state.allergenOrderArticles[action.payload.allergenId];
      localStorage.setItem("V5.shoppingCart.allergenOrderArticles", JSON.stringify(state.allergenOrderArticles));
    },
    addAllergenOrderArticle: (state, action: PayloadAction<{ allergenId: string; orderArticle: OrderArticle }>) => {
      state.allergenOrderArticles[action.payload.allergenId] = action.payload.orderArticle;
      localStorage.setItem("V5.shoppingCart.allergenOrderArticles", JSON.stringify(state.allergenOrderArticles));
    },
    setDiscountCardNr: (state, action) => {
      state.discountCardNr = action.payload;
    },
    sharedShoppingCartReceived: (state, action: PayloadAction<SharedShoppingCart>) => {
      state.sharedShoppingCart = action.payload;
    },
    sharedShoppingCartTimerElapsed: (state) => {
      if (state.sharedShoppingCart) {
        state.sharedShoppingCart.readyToOrder = null;
      }
    },
    sharedShoppingCartTransactionsReceived: (state, action: PayloadAction<{ uuid: string }[]>) => {
      state.sharedShoppingCartTransactionUuidsProcessed = {};
      action.payload.forEach((transaction) => {
        state.sharedShoppingCartTransactionUuidsProcessed[transaction.uuid] = true;
      });
    },
    sharedShoppingCartTransactionsProcessed: (state, action: PayloadAction<{ uuid: string }[]>) => {
      action.payload.forEach((transaction) => {
        state.sharedShoppingCartTransactionUuidsProcessed[transaction.uuid] = true;
      });
    },
    setJamezzPaymentMethod: (state, { payload: paymentMethod }: PayloadAction<JamezzPaymentMethod>) => {
      state.jamezzPaymentMethod = paymentMethod;
      if (paymentMethod.payMethod === "CONTANT") {
        state.jamezzPaymentMethod.payProvider = "CASH";
      }
      localStorage.setItem("V5.shoppingCart.jamezzPaymentMethod", JSON.stringify(paymentMethod));
    },
    setInvalidOptionGroup: (state, action: PayloadAction<{ orderOptionGroup: OrderOptionGroup; invalid: boolean }>) => {
      state.invalidOrderOptionGroups[action.payload.orderOptionGroup.id] = action.payload.invalid;
    },
    trayDeletedFromShoppingCart: (state, { payload: itemsToDelete }: PayloadAction<string[]>) => {
      itemsToDelete.forEach((item) => {
        const itemIndex = state.items.findIndex((cartItem) => cartItem.uuid === item);
        state.items.splice(itemIndex, 1);
      });
    },
    setAfterPayData: (state, action: PayloadAction<OutstandingBalanceResponse | null>) => {
      state.afterPayData = action.payload;
    },
    setShoppingCart: (state, action: PayloadAction<ShoppingCart | null>) => {
      state.vouchers = [];
      state.serverSideShoppingCart = action.payload;
      if (action.payload) {
        localStorage.setItem("V5.shoppingCart.serverSideShoppingCart", JSON.stringify(state.serverSideShoppingCart));
      } else {
        localStorage.removeItem("V5.shoppingCart.serverSideShoppingCart");
      }
    },
    shoppingCartConfirmationResetted: (state) => {
      state.shoppingCartConfirmation.requestSentForUuid = null;
      state.shoppingCartConfirmation.response = null;
      state.shoppingCartConfirmation.waitingForRequestDialogIsOpen = false;
    },
    requestSentForUuid: (state, action: PayloadAction<string>) => {
      state.shoppingCartConfirmation.requestSentForUuid = action.payload;
      state.shoppingCartConfirmation.response = null;
      state.shoppingCartConfirmation.waitingForRequestDialogIsOpen = false;
    },
    shoppingCartConfirmationResponseReceived: (state, action: PayloadAction<ShoppingCartConfirmation>) => {
      state.shoppingCartConfirmation.response = action.payload;
      state.shoppingCartConfirmation.waitingForRequestDialogIsOpen = false;
    },
    waitingForRequestDialogIsOpened: (state) => {
      state.shoppingCartConfirmation.waitingForRequestDialogIsOpen = true;
    },
    partialOrderCreated: (
      state,
      { payload: { excludedItemIds } }: PayloadAction<{ excludedItemIds: Article["id"][] }>
    ) => {
      state.excludedFromOrdering = excludedItemIds;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setSalesarea, (state, { payload: { shared_shopping_cart } }) => {
        state.isSharedShoppingCartEnabled = !!shared_shopping_cart?.enabled;
        state.excludedFromOrdering = [];
      })
      .addCase(appInitialized, (state) => {
        const shoppingCartJson = localStorage.getItem("V5.shoppingCart.items");
        let shoppingCart: OrderArticle[] = [];
        if (shoppingCartJson != null) {
          shoppingCart = JSON.parse(shoppingCartJson) as OrderArticle[];
        }
        state.items = shoppingCart.filter((item) => item.added_origin != OrderArticleOrigin.SYSTEM);
      });
  },
});

export const selectAutoAddedItems = createSelector(
  [selectArticlesMap, (state) => state.global.salesarea.autoAddProductId],
  (articlesMap, autoAddProductId) => {
    const items: OrderArticle[] = [];
    if (typeof autoAddProductId === "number") {
      try {
        const orderArticle = initSystemProduct(articlesMap, autoAddProductId);
        items.push(orderArticle);
      } catch (e) {
        console.log(e);
      }
    }
    return items;
  }
);

export const selectCountsArticles = createSelector(
  (state: RootState) => state.shoppingCart.items,
  selectOrderArticlesFromVouchersV2,
  (items, voucherOrderArticles) => {
    const countsOfArticles: Record<string, number> = {};

    [...items, ...voucherOrderArticles].forEach((item) => {
      if (!countsOfArticles[item.article.id]) {
        countsOfArticles[item.article.id] = 0;
      }
      countsOfArticles[item.article.id] += item.count;
    }, 0);
    return countsOfArticles;
  }
);

export const selectArticlesSum = createSelector(selectCountsArticles, (countsOfArticles) => {
  return Object.values(countsOfArticles).reduce((sum, obj) => {
    return sum + obj;
  }, 0);
});

export function initSystemProduct(articlesMap: Record<string, Article>, product_id: number) {
  const article = _.cloneDeep(articlesMap[product_id]);
  if (!article) {
    // article doesn't exist (anymore) or salesarea doesn't have permissions to read it
    throw new Error(`Article ${product_id} linked to 'used as system product' does not exist`);
  }
  const orderArticle = initOrderArticle(articlesMap, article, 1);
  orderArticle.added_origin = OrderArticleOrigin.SYSTEM;

  return orderArticle;
}

// Action creators are generated for each case reducer function
export const {
  orderArticlePushedBySystem,
  orderArticlesPushedByUser,
  orderArticlesReceivedFromTransactions,
  clearShoppingCart,
  setVouchers,
  sharedShoppingCartReceived,
  sharedShoppingCartTransactionsProcessed,
  sharedShoppingCartTransactionsReceived,
  voucherRemoved,
  piggyRewardClaimed,
  setAllergenOrderArticles,
  addAllergenOrderArticle,
  removeAllergenOrderArticle,
  setDiscountCardNr,
  propposScanReceived,
  setJamezzPaymentMethod,
  setInvalidOptionGroup,
  trayDeletedFromShoppingCart,
  clearSharedShoppingCart,
  setAfterPayData,
  orderSucceeded,
  setShoppingCart,
  shoppingCartConfirmationResponseReceived,
  requestSentForUuid,
  shoppingCartConfirmationResetted,
  waitingForRequestDialogIsOpened,
  partialOrderCreated,
  sharedShoppingCartTimerElapsed,
} = shoppingCartSlice.actions;

export default shoppingCartSlice.reducer;

import { rtkQueryApi } from "./rtkQueryApi";
import { JamezzApiResponse } from "../../../../types/shared/api/common_types";
import {
  ContactByIdentifierResponse,
  CreateAcountResponse,
  LoggedInUserResponse,
  SendContactEmailResponse,
  SendVerificationCodeResponse,
} from "../../../../types/shared/piggy";
import { piggyApi } from "./piggyApi";
import { piggyUserLoggedIn } from "../piggySlice";
import _ from "lodash";

const piggyEndpoints = rtkQueryApi.enhanceEndpoints({
  addTagTypes: ["piggyAuth"],
});

const eightHours = 60 * 60 * 8;
export const piggyAuthApi = piggyEndpoints.injectEndpoints({
  endpoints: (builder) => ({
    getLoggedinUser: builder.query<LoggedInUserResponse, void>({
      query: () => {
        return {
          url: `/v5_2/piggy/contact-session`,
        };
      },
      keepUnusedDataFor: eightHours,
      providesTags: ["piggyAuth"],
    }),
    sendEmail: builder.mutation<SendContactEmailResponse, string>({
      query: (email_address) => {
        return {
          url: `/v5_2/piggy/contact-send-mail`,
          method: "POST",
          body: {
            contact_email: email_address,
          },
        };
      },
    }),
    sendVerificationCode: builder.mutation<SendVerificationCodeResponse, { code: string; contact_email: string }>({
      query: (body) => {
        return {
          url: `/v5_2/piggy/contact-verify-code`,
          method: "POST",
          body,
        };
      },
      async onQueryStarted({ code, contact_email }, { dispatch, queryFulfilled }) {
        // https://redux-toolkit.js.org/rtk-query/usage/manual-cache-updates#pessimistic-updates
        try {
          const { data: response } = await queryFulfilled;
          dispatch(
            piggyAuthApi.util.updateQueryData("getLoggedinUser", undefined, (draft) => {
              if ("piggy_response" in response.data) {
              } else if ("has_session" in response.data) {
                draft.data.has_session = response.data.has_session;
                draft.data.piggy_contact_email = response.data.piggy_contact_email;
              }
            })
          );
          dispatch(piggyApi.util.invalidateTags(["piggy-balance"]));
        } catch {}
      },
    }),
    createAccount: builder.mutation<CreateAcountResponse, string>({
      query(contact_email) {
        return {
          url: `/v5_2/piggy/contact-create`,
          method: "POST",
          body: {
            contact_email,
          },
        };
      },
    }),
    logout: builder.mutation<LoggedInUserResponse, void>({
      query() {
        return {
          url: "/v5_2/piggy/contact-logout",
          method: "POST",
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            piggyAuthApi.util.updateQueryData("getLoggedinUser", undefined, (draft) => {
              draft.data.has_session = false;
              draft.data.piggy_contact_email = null;
              draft.data.piggy_contact_uuid = null;
            })
          );
        } catch {}
      },
    }),
    contactByIdentifier: builder.query<
      ContactByIdentifierResponse & JamezzApiResponse,
      { identifier: string; timestamp: number; create_if_not_exists: boolean }
    >({
      query: ({ identifier, timestamp, create_if_not_exists }) => {
        return {
          url: `/v5_2/piggy/contact-by-identifier`,
          method: "POST",
          body: {
            contact_identifier: identifier,
            timestamp,
            create_if_not_exists,
          },
        };
      },
      async onQueryStarted({ identifier }, { dispatch, queryFulfilled }) {
        try {
          const { data: response } = await queryFulfilled;

          dispatch(
            piggyAuthApi.util.updateQueryData("getLoggedinUser", undefined, (draft) => {
              draft.data.has_session = true;
              draft.data.piggy_contact_uuid = response.data.uuid;
              draft.data.contact = response.data.contact;
              draft.timestamp = response.timestamp;
            })
          );
          dispatch(piggyUserLoggedIn({ code: identifier }));
        } catch (err) {
          if (assertIsMyError(err)) {
            // toast.warning("Unknown loyalty card found.", { toastId: "unknown loyalty" });
          }
        }
      },
    }),
  }),
});

function assertIsMyError(
  err: unknown
): err is {
  error: {
    data: {
      message: string;
    };
  };
} {
  return typeof err === "object" && err != null && _.has(err, "error.data.message");
}
export const {
  useSendEmailMutation,
  useSendVerificationCodeMutation,
  useCreateAccountMutation,
  useGetLoggedinUserQuery,
  useLazyGetLoggedinUserQuery,
  useLogoutMutation,
  useContactByIdentifierQuery,
  useLazyContactByIdentifierQuery,
} = piggyAuthApi;
